import React, {Component} from "react";
import "./Portfolio.css";

class Portfolio extends Component {

    render() {
        return (
            <div id="portfolio">
                This is my portfolio!
            </div>
        );
    }
}

export default Portfolio;
